uniform float size;
uniform float time;


float random (vec2 st) {
    return fract(sin(dot(st.xy,
                         vec2(12.9898,78.233)))*
        43758.5453123);
}

void main()
{

    vec4 modelPos = modelMatrix * vec4(position,1.0);
    vec4 viewPos = viewMatrix *  modelPos;
    vec4 projectionPos = projectionMatrix * viewPos;
    gl_Position = projectionPos;

    gl_PointSize = size;
}