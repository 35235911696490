import * as THREE from 'three'
import { randFloat } from 'three/src/math/MathUtils.js'

import PointManager from './pointsManager'
export default class PointsGenerator
{
    constructor(plane, scene)
    {
        this.plane = plane
        this.scene = scene
        this.count = 0
        this.GenerateSphereMesh()
        this.pointManager = new PointManager() 

    }

    GenerateSphereMesh()
    {
        const geo = new THREE.SphereGeometry(1,16,16)
        const material = new THREE.MeshBasicMaterial( { color: 'red' } );
        this.mesh = new THREE.Mesh(geo,material)
        this.mesh.position.copy(new THREE.Vector3(0,0,-60))

        this.pointCollider = new THREE.Sphere(this.mesh.position,1)

        this.scene.add(this.mesh) 
    }

    
    CheckForCollision(sphereCollider)
    {
        if(this.pointCollider.intersectsSphere(sphereCollider) && this.count==0)
        {
            this.count++
            console.log("Collided")
            this.MoveSphereToNewPos()
        }
    }

    MoveSphereToNewPos()
    {
    
        this.pointManager.IncreasePoint()
        let newPos = new THREE.Vector3(randFloat(-1,1), randFloat(-1,1),randFloat(-1,1))
        newPos.normalize()
        newPos.multiplyScalar(randFloat(5,10)).add(this.plane.position.clone())
        this.mesh.position.copy(newPos)
        this.count = 0
    }
}