uniform float time;

void main()
{
    float power = 10.0;
    float glow = power;
    vec2 uv = gl_PointCoord;
    float length = distance(uv, vec2(0.5)) * 2.0;
    length = 1.0 - length;
    length = pow(length,glow);
    gl_FragColor = vec4(vec3(1.0),length*2.0);
}