// script.js
export default class PointManager {
    constructor() {
        document.addEventListener("DOMContentLoaded", () => {
            this.counterElement = document.getElementById("counter");
            this.counterElementHolder = document.querySelector('div.flex-CountContainer')
            this.counter = 0;
        });
    }

    IncreasePoint() {
        this.counter++;
        this.counterElement.textContent = this.counter;
        this.counterElementHolder.classList.add('animate');

        this.counterElementHolder.addEventListener('animationend', () => {
            this.counterElementHolder.classList.remove('animate')
        }, { once: true })
    }
}

