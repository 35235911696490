import * as THREE from 'three'


export default class MouseInputManager {
    constructor(objectToControl) {
        this.objectToControl = objectToControl;


        this.mouseLeftDown = false;
        this.mousePos = new THREE.Vector2(0, 0)

        this.startingPos = new THREE.Vector2(0, 0)
        this.previousMousePos = new THREE.Vector2(0, 0)


        this.forwardVector = new THREE.Vector3(0, 0, 1);
        this.currentForwardVector = new THREE.Vector3(0, 0, 0);
        this.currentForwardVector = this.objectToControl.getWorldDirection(this.currentForwardVector)
        this.newPosition = new THREE.Vector3(0, 0, 0);
        this.newPosition = objectToControl.position.clone()
        this.rotX = 0
        this.rotY = 0
        this.rotZ = 0

        this.newForwardVector = new THREE.Vector3(0, 0, 0)

        let tempForward = this.forwardVector.clone()
        console.log(tempForward)
        let tempForwardWorld = this.forwardVector.clone()
        this.objectToControl.localToWorld(tempForwardWorld)
        console.log(tempForwardWorld)
        console.log("forward printed")



        window.addEventListener('mousedown', (event) => {
            if (event.button == 0) {
                this.mousePos.x = event.pageX;
                this.mousePos.y = event.pageY;

                this.startingPos = this.mousePos.clone()
                this.previousMousePos = this.mousePos.clone()

                this.objectToControl.getWorldDirection(this.newForwardVector)
                console.log(this.currentForwardVector)

                this.mouseLeftDown = true
                console.log("Mouse Down")
            }
        })

        window.addEventListener('touchstart', (event) => {
            this.mousePos.x = event.touches[0].pageX;
            this.mousePos.y = event.touches[0].pageY;

            this.startingPos = this.mousePos.clone()
            this.previousMousePos = this.mousePos.clone()

            this.objectToControl.getWorldDirection(this.newForwardVector)
            console.log(this.currentForwardVector)

            this.mouseLeftDown = true
            console.log("Mouse Down")
        })


        window.addEventListener('mousemove', (event) => {
            if (this.mouseLeftDown) {
                this.mousePos.x = event.pageX;
                this.mousePos.y = event.pageY;
                //console.log(this.mousePos)
            }
        })

        window.addEventListener('touchmove',(event)=>
        {
            this.mousePos.x = event.touches[0].pageX;
            this.mousePos.y = event.touches[0].pageY;
        })

        window.addEventListener('mouseup', (event) => {
            if (event.button == 0) {
                this.mouseLeftDown = false
                this.newForwardVector = new THREE.Vector3(0, 0, 0)
            }
        })

        window.addEventListener('touchend',(event)=>
        {
            this.mouseLeftDown = false
            this.newForwardVector = new THREE.Vector3(0, 0, 0)
        })

        window.addEventListener('touchcancel',(event)=>
            {
                this.mouseLeftDown = false
                this.newForwardVector = new THREE.Vector3(0, 0, 0)
            })

    }

    NewHandleMouseInput(deltaTime) {

        let diff = new THREE.Vector2(0, 0);
        let temprotX = this.objectToControl.rotation.x
        let temprotY = this.objectToControl.rotation.y

        if (this.mouseLeftDown) {
            let currentStartingPos = new THREE.Vector2(0, 0)
            currentStartingPos = this.startingPos.clone()

            let currentMousePos = new THREE.Vector2(0, 0);
            currentMousePos = this.mousePos.clone()

            diff.subVectors(currentMousePos.clone(), currentStartingPos.clone());
            diff.x = diff.x / window.innerWidth;
            diff.y = diff.y / window.innerHeight;
            //console.log(diff)

            this.startingPos = currentMousePos.clone()

            temprotX += diff.y * Math.PI * 10
            temprotY += -diff.x * Math.PI * 10

            //console.log(rotx)

            // this.currentForwardVector.lerp(this.newForwardVector,0.1)
            // this.currentForwardVector.normalize()

            // targetPosition = new THREE.Vector3().addVectors(this.objectToControl.position, this.currentForwardVector);



        }
        else {

            //this.rotX = THREE.MathUtils.lerp(this.objectToControl.rotation.x, 0, 0.1);
            // this.currentForwardVector.lerp(this.forwardVector,0.1)

            // targetPosition = new THREE.Vector3().addVectors(this.objectToControl.position, this.currentForwardVector);

            // this.currentForwardVector.normalize()
        }

        this.objectToControl.getWorldDirection(this.currentForwardVector)
        //console.log(this.currentForwardVector)
        //this.objectToControl.lookAt(targetPosition);

        let rot = -diff.x * Math.PI * 100;

        this.rotZ = THREE.MathUtils.lerp(this.rotZ, rot, 0.01);
        this.rotX = THREE.MathUtils.lerp(this.rotX, temprotX, 0.05);
        this.rotY = THREE.MathUtils.lerp(this.rotY, temprotY, 0.05);

        const euler = new THREE.Euler(this.rotX, this.rotY, this.rotZ, 'YXZ')

        this.objectToControl.rotation.copy(euler)

        this.newPosition.copy(this.objectToControl.position).add(this.currentForwardVector.clone().multiplyScalar(-0.2));
        this.objectToControl.position.copy(this.newPosition);
    }

    HandleMouseInput(deltaTime) {

        let deltaTimeMultiplier = 100

        let diff = new THREE.Vector2(0, 0);
        let temprotX = this.objectToControl.rotation.x
        let temprotY = this.objectToControl.rotation.y

        if (this.mouseLeftDown) {
            let currentStartingPos = new THREE.Vector2(0, 0)

            currentStartingPos = this.startingPos.clone()
            currentStartingPos.x /= window.innerWidth
            currentStartingPos.y /= window.innerHeight

            let currentMousePos = new THREE.Vector2(0, 0);
            currentMousePos = this.mousePos.clone()
            currentMousePos.x /= window.innerWidth
            currentMousePos.y /= window.innerHeight

            diff.subVectors(currentMousePos, currentStartingPos);

            //console.log(diff)

            //this.startingPos = currentMousePos.clone()

            temprotX += diff.y * Math.PI * 0.1 * deltaTime
            temprotY += -diff.x * Math.PI * 0.2 * deltaTime

            temprotX = THREE.MathUtils.clamp(temprotX, -1.3, 1.3)
            //console.log(rotx)

            // this.currentForwardVector.lerp(this.newForwardVector,0.1)
            // this.currentForwardVector.normalize()

            // targetPosition = new THREE.Vector3().addVectors(this.objectToControl.position, this.currentForwardVector);



        }
        else {

            //this.rotX = THREE.MathUtils.lerp(this.objectToControl.rotation.x, 0, 0.1);
            // this.currentForwardVector.lerp(this.forwardVector,0.1)

            // targetPosition = new THREE.Vector3().addVectors(this.objectToControl.position, this.currentForwardVector);

            // this.currentForwardVector.normalize()
        }

        this.objectToControl.getWorldDirection(this.currentForwardVector)
        //console.log(this.currentForwardVector)
        //this.objectToControl.lookAt(targetPosition);

        let rot = -diff.x * Math.PI * 0.1 * deltaTime;

        this.rotZ = THREE.MathUtils.lerp(this.rotZ, rot, 0.001 * deltaTime);
        this.rotX = THREE.MathUtils.lerp(this.rotX, temprotX, 0.001 * deltaTime);
        this.rotY = THREE.MathUtils.lerp(this.rotY, temprotY, 0.001 * deltaTime);

        const euler = new THREE.Euler(this.rotX, this.rotY, this.rotZ, 'YXZ')

        this.objectToControl.rotation.copy(euler)

        this.newPosition.copy(this.objectToControl.position).add(this.currentForwardVector.clone().normalize()
        .multiplyScalar(-0.004   * deltaTime));

        this.objectToControl.position.copy(this.newPosition);

    }

    UpdateCameraPosition(camera, offset = new THREE.Vector3(0, 5, -10), deltaTime) {
        // Clone the object's position and add the offset
        let cameraGoToPos = this.objectToControl.position.clone();
        let cameraForward = new THREE.Vector3(0,0,0)
        camera.getWorldDirection(cameraForward)
        let forwardVector = this.currentForwardVector.clone().normalize();
        let rightVector = new THREE.Vector3().crossVectors(forwardVector, new THREE.Vector3(0, 1, 0)).normalize();
        let upVector = new THREE.Vector3().crossVectors(rightVector, forwardVector).normalize();

        cameraGoToPos.add(forwardVector.multiplyScalar(offset.z))
        cameraGoToPos.add(upVector.multiplyScalar(offset.y))

        console.log(cameraGoToPos)

        const targetPosition = cameraGoToPos

        // Move the camera to the new position
        camera.position.lerp(targetPosition, 0.005 * deltaTime); // Smooth transition

        cameraForward.add(camera.position)

        let LookAtDir = new THREE.Vector3(0,0,0)
        LookAtDir.lerpVectors(cameraForward,this.objectToControl.position,deltaTime * 0.005)
        // Make the camera look at the object
        camera.lookAt(LookAtDir);
    }

}