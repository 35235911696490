import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import GUI from 'lil-gui'


import ParticlesGenerator from './particlesGenerator'
import particlesVert from './Shaders/particlesVert.glsl'
import particlesFrag from './Shaders/particlesFrag.glsl'


import PathGenerator from './pathGenerator'
import { TransformControls } from 'three/addons/controls/TransformControls.js';

import { GLTFLoader, TriangleBlurShader } from 'three/examples/jsm/Addons.js'

import InputManager from './inputManager'
import MouseInputManager from './mouseInputController'

import PointManager from './pointsManager'
import PointsGenerator from './pointsGenerator'

/**
 * Base
 */
// Debug
const gui = new GUI()
const scene = new THREE.Scene()
const currentPointManager = new PointManager()

let update = false

CheckButtonClick()
function CheckButtonClick() {
    const btn = document.getElementById('OkBtn')
    const controlInfo = document.querySelector('div.control-info')

    btn.addEventListener('click', () => {
        controlInfo.classList.add('scaleDownElement');

        controlInfo.addEventListener('animationend', () => {
            controlInfo.classList.remove('scaleDownElement')
            controlInfo.style.display = 'none'
            update = true
        }, { once: true })

    })
}

// Canvas
const canvas = document.querySelector('canvas.webgl')
let skyTextureLoader = new THREE.TextureLoader()
let skyTexture = skyTextureLoader.load("./Images/gradient.jpg")
skyTexture.mapping = THREE.EquirectangularReflectionMapping
//skyTexture.generateMipmaps = false

let skyColor =
{
    topColor: '#87CEEB',
    midColor: '#FFFFFF',
    bottomColor: '#BA714A'
}

gui.addColor(skyColor, 'topColor').onChange(createGradientTexture)
gui.addColor(skyColor, 'midColor').onChange(createGradientTexture)
gui.addColor(skyColor, 'bottomColor').onChange(createGradientTexture)


const textureCanvas = document.createElement('canvas');
textureCanvas.width = 512;
textureCanvas.height = 512;

createGradientTexture();
function createGradientTexture() {


    const ctx = textureCanvas.getContext('2d');

    // Create gradient
    const gradient = ctx.createLinearGradient(0, 0, 0, textureCanvas.height);

    gradient.addColorStop(0.3, skyColor.topColor); // Sky blue
    gradient.addColorStop(0.5, skyColor.midColor); // Sky blue
    gradient.addColorStop(0.8, skyColor.bottomColor); // White


    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, textureCanvas.width, textureCanvas.height);

    // Create texture
    const texture = new THREE.CanvasTexture(textureCanvas);
    texture.mapping = THREE.EquirectangularReflectionMapping

    scene.background = texture
}

// Scene




//models
const gltfLoader = new GLTFLoader()
let gltfLoaded = false
let planeModel;

let currentMouseInutManager = null;
let modelLoaded = false
const meshGroup = new THREE.Group()
gltfLoader.load('/paper_plane/scene.gltf',
    (gltf) => {
        gltfLoaded = true
        const gltfScene = gltf.scene

        gltfScene.scale.y = 0.01
        gltfScene.scale.z = 0.01
        gltfScene.scale.x = 0.01

        gltfScene.rotation.y = Math.PI * 0.5

        meshGroup.add(gltfScene);
        //meshGroup.rotation.x = Math.PI
        planeModel = gltfScene;
        console.log(planeModel)
        const axesHelper = new THREE.AxesHelper(2);
        meshGroup.add(axesHelper);
        meshGroup.updateMatrixWorld(true);
        scene.add(meshGroup)
        modelLoaded = true
        currentMouseInutManager = new MouseInputManager(meshGroup)
    }

)

const planeSphereCollider = new THREE.Sphere(meshGroup.position, 1)

const colliderVisualizer = new THREE.SphereGeometry(1, 16, 16);

const sphereMaterial = new THREE.MeshBasicMaterial({ color: 'red', wireframe: true });
const sphereMesh = new THREE.Mesh(colliderVisualizer, sphereMaterial);
sphereMesh.position.copy(planeSphereCollider.center);
//scene.add(sphereMesh);


const pointsGenerator = new PointsGenerator(meshGroup, scene)

// controller
//..

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('textures/matcaps/8.png')
matcapTexture.colorSpace = THREE.SRGBColorSpace

/**
 * Fonts
 */
// const fontLoader = new FontLoader()

// fontLoader.load(
//     '/fonts/helvetiker_regular.typeface.json',
//     (font) =>
//     {
//         // Material
//         const material = new THREE.MeshBasicMaterial({ matcap: matcapTexture })

//         // Text
//         const textGeometry = new TextGeometry(
//             "Ujjawal's Portfolio",
//             {
//                 font: font,
//                 size: 0.5,
//                 depth: 0.1,
//                 curveSegments: 5,
//                 bevelEnabled: true,
//                 bevelThickness: 0.03,
//                 bevelSize: 0.02,
//                 bevelOffset: 0,
//                 bevelSegments: 5
//             }
//         )
//         textGeometry.center()

//         const text = new THREE.Mesh(textGeometry, material)
//         scene.add(text)

//         // Donuts
//         const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 32, 64)

//         for(let i = 0; i < 100; i++)
//         {
//             const donut = new THREE.Mesh(donutGeometry, material)
//             donut.position.x = (Math.random() - 0.5) * 10
//             donut.position.y = (Math.random() - 0.5) * 10
//             donut.position.z = (Math.random() - 0.5) * 10
//             donut.rotation.x = Math.random() * Math.PI
//             donut.rotation.y = Math.random() * Math.PI
//             const scale = Math.random()
//             donut.scale.set(scale, scale, scale)

//             scene.add(donut)
//         }
//     }
// )

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

const particleMat = new THREE.ShaderMaterial(
    {
        vertexShader: particlesVert,
        fragmentShader: particlesFrag,
        transparent: true,
        depthTest: false,
        uniforms:
        {
            size:
            {
                value: 50
            },
            time:
            {
                value: 0
            }
        }
    }
)

gui.add(particleMat.uniforms.size, 'value', 1, 200, 1).onChange((value) => {
    console.log(value)
    particleMat.uniforms.size.value = value
});

const curveParams = {
    lerpValue: 0
}

gui.add(curveParams, 'lerpValue', 0, 1, 0.001)

let currentParticleGenerator = new ParticlesGenerator(500, 10, 10, 10, particleMat);

//scene.add(currentParticleGenerator.GenerateParticles())

const pathGenerator = new PathGenerator()
const pathHelper = pathGenerator.GenerateCurve()
const currentPath = pathGenerator.curve
//scene.add(pathHelper)


const axesHelper = new THREE.AxesHelper(10);
scene.add(axesHelper);

const boxMesh = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1, 2, 2, 2),
    new THREE.MeshBasicMaterial(
        {
            color: 'red'
        }
    ))
//scene.add(boxMesh)

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1000)
camera.position.x = 0
camera.position.y = 2
camera.position.z = 6
scene.add(camera)

// Controls
//const controls = new OrbitControls(camera, canvas)
//controls.enableDamping = true

const transformControl = new TransformControls(camera, canvas);

const helper = new THREE.GridHelper(2000, 100);
helper.position.y = 0;
helper.material.opacity = 1;
helper.material.transparent = true;
scene.add(helper);
/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})

renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const currentInputManager = new InputManager(meshGroup)
gui.add(currentInputManager, 'accleration', 0.01, 10, 0.01)

let time = Date.now()

const tick = () => {
    if (update) {

        let currentTime = Date.now()
        let deltaTime = currentTime - time
        time = currentTime
        const elapsedTime = clock.getElapsedTime()
        particleMat.uniforms.time.value = elapsedTime

        // const point = currentPath.getPointAt(curveParams.lerpValue);
        // const diff = boxMesh.position  - point

        // boxMesh.position.copy(point)
        //boxMesh.lookAt(currentPath.getPointAt(Math.min(1, curveParams.lerpValue+0.1)))

        //currentInputManager.CalculateCurrentPosition()
        if (modelLoaded) {
            //currentMouseInutManager.NewHandleMouseInput(clock.getDelta())
            currentMouseInutManager.UpdateCameraPosition(camera, new THREE.Vector3(0, 2, 6),deltaTime)
            currentMouseInutManager.HandleMouseInput(deltaTime)
           
            //planeSphereCollider.position.copy(meshGroup.position)
            //sphereMesh.position.copy(planeSphereCollider.center)

            pointsGenerator.CheckForCollision(planeSphereCollider)
        }


        if (gltfLoaded) {
            // meshGroup.lookAt(currentPath.getPointAt(Math.min(1, curveParams.lerpValue+0.01)))
            // meshGroup.position.copy(point)

        }

        //camera.lookAt(boxMesh.position)
        // Update controls
        //controls.update()

        // Render
        renderer.render(scene, camera)
    }

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()