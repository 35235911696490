import * as THREE from 'three'


export default class CurveGenerator
{
    

    GenerateCurve()
    {
        this.curve = new THREE.CatmullRomCurve3([
            new THREE.Vector3(0, 0, 0),
            new THREE.Vector3(10, 5, -5),
            new THREE.Vector3(-8, -5, -8),
            new THREE.Vector3(-10, 5, -10)
            
        ]);
        
        const points = this.curve.getPoints(50);
        const geometry = new THREE.BufferGeometry().setFromPoints(points);
        
        const material = new THREE.LineBasicMaterial({ color: 0xff0000 });
        
        // Create the final object to add to the scene
        const curveObject = new THREE.Line(geometry, material);
        return curveObject;
       }

}
