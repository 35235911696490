import * as THREE from 'three'

export default class InputManager
{
    constructor(objectToControl)
    {
        this.objectToControl = objectToControl;

        this.currentPos = objectToControl.position
        this.previousPos = this.currentPos.clone()

        this.VelocityX = 0
        this.currentXvelocity = 0
        this.gotoPos = new THREE.Vector3(0,0,0)

        this.accleration = 0.5
        this.rotZ = 0

        this.isKeyDown = false

        console.log("constructor")

        window.addEventListener("keydown", ()=>
        {
            //console.log(event)
            if(event.key ==="ArrowRight")
            {
                //console.log("going right");
                this.VelocityX = 1
                this.gotoPos = new THREE.Vector3(4,0,0)
    
            }
            else if(event.key ==="ArrowLeft")
            {
                //console.log("going left")
                this.VelocityX = -1
                this.gotoPos = new THREE.Vector3(-4,0,0)
               
            }
            else
            {
                this.VelocityX = 0
                this.gotoPos = new THREE.Vector3(0,0,0)
                
            }
        
        });

        window.addEventListener("keyup", ()=>
            {
               this.gotoPos = new THREE.Vector3(0,0,0)
            });
    }

    CalculateCurrentPosition()
    {
        let currentGotoPos = this.gotoPos;

        this.previousPos = this.currentPos.clone()

        this.objectToControl.position.lerp(currentGotoPos,0.04)

        this.currentPos = this.objectToControl.position

        let diff = new THREE.Vector3()

        diff.subVectors(this.currentPos,this.previousPos)
        //console.log(diff)
        // console.log(this.currentPos)
        // console.log(this.previousPos)
        //console.log(`current pos ${this.currentPos} and previous pos ${this.previousPos}` )

        let gotoAngle = -(diff.x * Math.PI * 0.25) * 10

        this.rotZ = THREE.MathUtils.lerp(this.rotZ, gotoAngle, 0.1);

        //console.log(this.rotZ)

        this.objectToControl.rotation.z = this.rotZ


        currentGotoPos = new THREE.Vector3(0,0,0)
    }
}